'use client';

import { Divider, Stack } from '@mantine/core';
import SSOButton from '@/app/(auth)/components/SSOButton';
import GoogleIcon from '@/icons/google.svg';
import MicrosoftIcon from '@/icons/microsoft.svg';
import GithubIcon from '@/icons/github.svg';
import { getSSOProviders } from '@/app/(auth)/actions/sso';
import { useEffect, useState } from 'react';
import { SSOProvider } from '@/types/sso';
import { useGrowthbookContext } from '@/app/contexts/GrowthbookContext';

interface SSOProvidersProps {
  buttonLabel?: string;
}

export default function SSOProviders({ buttonLabel = 'Sign in with' }: SSOProvidersProps) {
  const growthbook = useGrowthbookContext();
  const ssoEnabled = growthbook?.isOn('sso');

  const [providers, setProviders] = useState<SSOProvider[] | null>(null);

  useEffect(() => {
    if (ssoEnabled) {
      (async () => {
        const providerResponse = await getSSOProviders();
        if (providerResponse.ok && providerResponse.data && providerResponse.data.length) {
          setProviders(providerResponse.data);
        }
      })();
    }
  }, [ssoEnabled]);

  if (!providers) {
    return null;
  }

  const icons: Record<string, JSX.Element> = {
    google: <GoogleIcon />,
    microsoft: <MicrosoftIcon />,
    github: <GithubIcon />,
  };

  const providerElements = providers.map((provider) => {
    const icon = icons?.[provider.id] ?? null;
    return (
      <SSOButton key={provider.id} icon={icon} href={provider.url}>
        {buttonLabel} {provider.name}
      </SSOButton>
    );
  });

  return (
    <>
      <Divider my='sm' label='or' labelPosition='center' />
      <Stack align={'stretch'} px={14} gap='xs'>
        {providerElements}
      </Stack>
    </>
  );
}
